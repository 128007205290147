.pageNotFound {
  background-color: #f8f8f8;
  width: 100%;
  padding: 30px 30px;
  min-height: 95vh;
}
.pageNotFound .mainDiv {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 83vh;
  width: 100%;
  max-width: 1760px;
  margin: 0 auto;
}
.pageNotFound .mainDiv img {
  width: 300px;
  margin-bottom: 40px;
}
.pageNotFound .mainDiv h1 {
  color: #644e77;
  font: 14px "BasisGrotesquePro-Medium";
  margin: 0;
  margin-bottom: 12px;
}
.pageNotFound .mainDiv h5 {
  color: #644e77;
  font: 10px "basis_grotesque_proregular";
  margin: 0;
}
.pageNotFound .mainDiv a {
  width: 100%;
}
.pageNotFound .mainDiv .primaryButton {
  margin: 0;
}

/* // Extra large devices (ipad) */
@media (min-width: 768px) {
  .pageNotFound {
    padding: 30px 40px 60px;
    min-height: 95vh;
  }
  .pageNotFound .mainDiv {
    justify-content: center;
    height: 90vh;
  }
  .pageNotFound .mainDiv img {
    width: 540px;
    margin-top: 100px;
  }
  .pageNotFound .mainDiv h1 {
    font: 24px "basis_grotesque_probold";
  }
  .pageNotFound .mainDiv h5 {
    font: 14px "basis_grotesque_proregular";
  }
  .pageNotFound .mainDiv a {
    width: 233px;
    margin-top: 30px;
    margin-bottom: 100px;
  }
}

/* // Extra large devices (ipad) */
@media (min-width: 1920px) {
  .pageNotFound .mainDiv {
    justify-content: center;
    height: auto;
  }
  .pageNotFound .mainDiv img {
    width: 998.96px;
  }
  .pageNotFound .mainDiv a {
    width: 273px;
  }
  .pageNotFound .mainDiv .primaryButton {
    width: 100%;
    font-size: 16px;
    height: 48px;
  }
}
