.heroBannerWrapper{
	padding: 53px 160px 68px;
    position: relative;
}
.heroBannerWrapper .bannerBGLeft {
    position: absolute;
    width: 518px;
    top: 0;
    left: 0;
    z-index: -1
}
.heroBannerWrapper .bannerBGLeft img {
    width: 100%;
    height: auto
}
.heroBannerWrapper .bannerDescriptionWrapper {
    position: relative;
    z-index: 2
}
.heroBannerWrapper .bannerDescriptionWrapper h1 {
    font-size: 46px;
    line-height: 54px;
    letter-spacing: -2px;
    color: #53356f;
    margin-bottom: 25px;
    font-family: "BasisGrotesquePro-Black"
}
.heroBannerWrapper .bannerDescriptionWrapper p {
    font-size: 20px;
    line-height: 25px;
    color: #1b2d49;
    padding: 0 170px 0 4px;
    letter-spacing: -0.6px;
    margin-bottom: 36px;
    font-family: "basis_grotesque_proregular"
}
.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper {
    padding-right: 25px;
    margin-bottom: 28px
}
.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li {
    width: 100% !important;
    max-width: 50% !important;
    font-size: 19px;
    line-height: 25px;
    color: #1b2d49;
    margin-bottom: 25px;
    position: relative;
    padding: 0 10px 0 28px;
    letter-spacing: -0.1px;
    font-family: "BasisGrotesquePro-Medium"
}
.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li .icon__wrapper {
    display: inline-block;
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: -2px;
    left: -2px
}
.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li .icon__wrapper img {
    width: 100%;
    height: auto
}

 .heroBannerWrapper .bannerDescriptionWrapper .btnWrapper {
    margin-bottom: 22px;
	margin-top: 10px;
}

.heroBannerWrapper .bannerDescriptionWrapper .btnWrapper .fs__btn {
    margin-bottom: 5px;
    font-family: "BasisGrotesquePro-Medium";
    text-decoration: none !important
}
.heroBannerWrapper .bannerDescriptionWrapper .btnWrapper small {
    display: block;
    font-size: 14px;
    line-height: 26px;
    color: #5a687c;
    letter-spacing: .16px
}
.heroBannerWrapper .bannerImg-Wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    overflow: hidden
}
 .heroBannerWrapper .bannerImg-Wrapper img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100% !important;
    width: 100% !important;
	object-fit: cover;
	object-position: left top;
}


@media screen and (max-width: 1600px) {
    .heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper {
        padding-right: 15px;
        margin-bottom: 20px
    }
	
}
@media screen and (max-width: 1390px) {
     .heroBannerWrapper .bannerImg-Wrapper img {
        right: -10%;
        right: -20%
    }
	.googleReview{
		min-width: auto;
	}
    .heroBannerWrapper .bannerDescriptionWrapper .btnWrapper .fs__btn{
        min-width: 100%;
    }
	
}
@media screen and (max-width: 1440px) {
    .heroBannerWrapper {
        padding: 40px 75px 50px
    }
	.heroBannerWrapper .bannerBGLeft {
        width: 418px
    }
	.heroBannerWrapper .bannerDescriptionWrapper h1 {
        font-size: 43px;
        line-height: 50px
    }
	.heroBannerWrapper .bannerDescriptionWrapper p {
        padding: 0 80px 0 4px;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 30px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li {
        padding: 0 15px 0 24px;
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 18px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li .icon__wrapper {
        width: 15px !important;
        height: 15px !important;
    }
	.heroBannerWrapper .bannerImg-Wrapper {
		width: 50% !important;
	}
	.heroBannerWrapper .bannerDescriptionWrapper .btnWrapper small{font-size: 12px;}
}
@media screen and (max-width: 1170px) {
    .heroBannerWrapper .bannerBGLeft {
        width: 318px
    }
	.heroBannerWrapper .bannerDescriptionWrapper h1 {
        font-size: 40px;
        line-height: 47px
    }
	.heroBannerWrapper .bannerDescriptionWrapper p {
        padding: 0 50px 0 4px;
        font-size: 17px;
        line-height: 22px;
        margin-bottom: 25px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li {
        padding: 0 15px 0 24px;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        max-width: 100% !important
    }
	.heroBannerWrapper .bannerImg-Wrapper {
		width: 50% !important   ;
	}
	.bannerBtn-GoogleReview .col-sm-5, .bannerBtn-GoogleReview .col-sm-7{
		flex: initial;
		max-width: inherit;
	}
	.googleReview{margin-top: 40px;}
}
@media screen and (max-width: 991px) {
    .heroBannerWrapper {
        padding: 30px 30px 20px
    }
	.heroBannerWrapper .bannerBGLeft {
        width: 218px
    }
	.heroBannerWrapper .bannerDescriptionWrapper h1 {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 15px
    }
	.heroBannerWrapper .bannerDescriptionWrapper p {
        padding: 0;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li {
        padding: 0 15px 0 18px;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 5px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li .icon__wrapper {
        width: 13px;
        height: 13px;
        top: -3px;
        left: -1px
    }
	 .heroBannerWrapper .bannerImg-Wrapper {
        width: 40%
    }
	 .heroBannerWrapper .bannerImg-Wrapper img {
        right: unset;
        left: 0
    }
}

@media screen and (max-width: 767px) {
    .heroBannerWrapper {
        padding: 35px 20px
    }
	.heroBannerWrapper .bannerDescriptionWrapper h1 {
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 20px
    }
	.heroBannerWrapper .bannerDescriptionWrapper p {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper {
        margin-bottom: 30px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li {
        padding: 0 15px 0 20px;
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 10px
    }
	.heroBannerWrapper .bannerDescriptionWrapper .benefitsWrapper ul li .icon__wrapper {
        width: 17px;
        height: 17px;
        top: -4px;
        left: -4px
    }
    .heroBannerWrapper .bannerDescriptionWrapper .btnWrapper{
        text-align: center;
    }
	.heroBannerWrapper .bannerDescriptionWrapper .btnWrapper .fs__btn {
        width: auto;
        padding: 8px 40px 10px 25px;
        min-width: 176px;
        font-size: 15px;
    }
	.heroBannerWrapper .bannerDescriptionWrapper .btnWrapper small {
        text-align: center;
        line-height: 15px;
        font-size: 10px;
    }
	 .heroBannerWrapper .bannerImg-Wrapper {
        display: none
    }
    
}