.status{
    background: #D1E2FB;
    border-radius: 65px;
    padding: 4px 10px;
}

.heading{
    padding: 15px;
}
.deleteSelected{
    float: right !important;
}
.inline{
  display: -webkit-inline-box;
  line-height: 30px;
  font-size: 14px;
  margin: 10px 2px 4px 7px;
}
.inline label{
    padding: 4px;
    }
    page-length{
      font-weight: 500;
    }
    .recordcounter{
      background: #EBEBEF;
      border: 1px solid #EBEBEF;
      border-radius: 4px;
      padding: 1px;
      padding: 4px;
    }
.searchBtn{
    margin-left: 6px;
width: 75px;
height: 40px;
border: 0ch;
background: #FB5100;
border-radius: 4px;
}
.text-align-center{
  text-align: center;
}

.tableDealer {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.tableDealerHeight{
  background-color: #D4D3E0;
  border: 2px solid #C1C0CD;
  white-space: nowrap; 
  border-left: 0px none;
  border-right: 0px none;
}
.tableDealerHeight th {
  padding: 4px 7px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    border-bottom-color: #E4E7EB !important;
    font-family: "BasisGrotesquePro-Medium";
    
}
.tr td{
  margin-right: 12px;
}
.icon-delete button, .icon-delete a{
    border: none;
    outline: none;
    background: none;
}
.borderonlyBiling {
  margin: -6px 0 26px 0;
  border-bottom: 1px solid #eaeaeb;
}
.input-group-text-biling {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem -2.25rem;
  padding-left: 12px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  border-right: 0px;
  white-space: nowrap;
  /* background-color: #e9ecef; */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
.form-control-border-left {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-left: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dealer-dtable tr td {
  font-size: 13px;
    color: #667085;
    font-weight: normal;
    font-family: "basis_grotesque_proregular";
    padding: 13px 7px;
    line-height: 19px;
    white-space: nowrap;
}
.bilingsubtitle{
  font-weight: normal;
  font-size: 12px;
  /* line-height: 25%; */
  color: #687083;
}
.DateRangePicker-modal{
  width: 45vw;
  align-items: center;
  /* max-width: 90vw; */
}
.btnDesignbillingSave {
  width: 120px;
  margin-right: 14px;
  height: 39px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-sizing: border-box;
  border-radius: 5px;
}
.botm{
  margin-bottom: 38px;
}
.billing-modal{
  width: 58vw;
  align-items: center;
  max-width: 94vw;
}
.btnAlienEnd {
  text-align: end;
  margin-top: 34px;
  margin-left: 2px;
}
.btnDone{
  background-color: #ECE8F4;
  border:0ch;
  width: 59px;
  height: 33px;
  background: #ECE8F4;
border-radius: 2px;
}
.justify-content{
  justify-content: normal;
}

.space-between {
  justify-content: space-between;
  width: 100%;
  /* padding-right: 1vw; */
  padding: 0px 25px;
  display: flex;
}

.TDays{
  width: 70px;
  height: 33px;
  background: #F6F7F7;
  border-radius: 4px;
  padding: 3px;
  text-align: center;
}
.tText{
  color: #475E68;
}
.activePage{
  display: inline-block;
  margin: 0 0 0 4px;
  padding: 0 0;
  background: #FB7333;
  /* background: #fbe8e0; */
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: "BasisGrotesquePro-Medium";
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  width: 28px;
  height: 28px;
  color: #FFFFFF;
  /* color: #828385; */
  line-height: 28px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px rgba(254, 86, 0, 0.23);
}
.firstPage{
  display: inline-block;
  margin: 0 0 0 4px;
  padding: 0 0;
  background: #EAEAEB;
  /* background: #fbe8e0; */
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: "BasisGrotesquePro-Medium";
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  width: 28px;
  height: 28px;
  color: #5E6F81;
  /* color: #828385; */
  line-height: 28px;
  cursor: pointer;
}
.btnClose{
  background-color: #FFFFFF;
  border:0ch;
  width: 59px;
  height: 33px;
  color: #8D9BA1;
}
.newAppBtn{
  background: #644E77;
    border-radius: 4px;
    border: none;
    outline: none;
    line-height: 16px;
    color: #ffffff;
    float: right;
    padding: 0px 16px;
    margin: 0px 10px;
    font-size: 12px;
    height: 32px;
}
.deleteSelected{
  background: #fb5100;
  border-radius: 4px;
  border: none;
  outline: none;
  line-height: 16px;
  color: #ffffff;
  padding: 0px 15px;
  font-size: 12px;
  height: 32px;
}
.customsearch{
  background-color: #FBFBFB;
    font-size: 14px;
    /* height: 100%; */
    border-color: #E6E6E6;
    padding: 18px;
    height: 40px;
}

.customsearch + span{
  background: #FBFBFB;
 border-color: #E6E6E6 !important;
}

.customsearch:focus {
  color: #212529;
  background-color: #FBFBFB;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}
.filtertxt{
  float: right;
  font-size: 14px;
  line-height: 18px;
  margin: 6px 12px;
  font-weight: 600;
  color: #3F2355;
}
.filtertxt .bi-funnel::before {
  content: "\f3e1";
  font-weight: 600 !important;
}
.DateRangePicker__CalendarSelection--start{
  left: 5px;
    border-radius: 50%;
    color: #fefefe !important;
    background: #644E77 !important;
    right: 5px;
    border: none;
}
.DateRangePicker__CalendarSelection--end{
  left: 5px;
    border-radius: 50%;
    color: #fefefe !important;
    background: #644E77 !important;
    right: 5px;
    border: none;
}
.DateRangePicker__CalendarHighlight--single{
  background-color: #644E77;
    border: 1px solid #644E77;
    border-radius: 50%;
    left: 5px;
    right: 5px;
    color: #fff !important;
}
.DateRangePicker__CalendarSelection {
  background-color: #F0F4FA;
  border: 1px solid #F0F4FA;
  bottom: 5px;
  left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.DateRangePicker__Date--is-selected {
    color: #475E68;
}
DateRangePicker__FullDateStates ~  .DateRangePicker__CalendarSelection--start + .DateRangePicker__DateLabel{
  color: #fff !important;
}
.DateRangePicker__Date--is-highlighted span{
       color: #fff !important;
}
/* .DateRangePicker__FullDateStates:has(+ .DateRangePicker__CalendarSelection--start) {
  color: #fff !important;
} */


.gridtxtancor a{
  color: #5039BF;
  text-decoration: none;
}
/* .Title{
    position: absolute;
    width: 429px;
    height: 32px;    
    font-family: Basis Grotesque Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 124%;    
    display: flex;
    align-items: center;    
    color: #3F2355;
} */

ul.customepagination{
    float: left !important;
    margin: 0px 0 30px 0 !important;
  }
  .customepagination .page-link {
    border-radius: 15px;
  }

  .customepagination .page-item:first-child .page-link {
    width: 15px;
  }

  .detaillinkbtn{
    color: #FB5100;
    background: #f9e6df;
    border-radius: 65px;
    padding: 6px 17px;
    text-decoration: none;
    font-size: 12px;
  }
  .deletelinkbtn{
    color: #FF0000;
  }

  .Dealer-Head{
    margin: 0 0 45px 10px;
    padding: 0;
  }
  .Dealer-Head h1{
    margin: 0;
    padding: 0;
    font-style: normal;
    font-family: "basis_grotesque_probold";
    font-weight: bold;
    font-size: 24px;
    color: #3f2355;
    text-align: left;
    text-decoration: none;
    line-height: 30px;
  }