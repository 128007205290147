.noLinkStyle {
  text-decoration: none;
}

.applicationHeaderAdmin {
  background-color: #fff;
  width: 100%;
  overflow: hidden;
}
.applicationHeaderAdmin .applicantSec {
  display: flex;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #e0e7ee;
  border-left: none;
}
.applicationHeaderAdmin .typeTag {
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: 0 0;
  background: #fb5100;
  transform: rotate(270deg);
  width: 100%;
  font: 14px "basis_grotesque_probold";
  color: #fff;
  padding: 12px 24px;
  flex-shrink: 0;
  height: fit-content;
  position: absolute;
  left: 0;
  bottom: -42px;
  font-weight: 700;
}

.applicationHeaderAdmin .applicantSec .innerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 17px 10px 10px;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 109px;
}
.contantSec{
  width: 100%;
}
.applicantSec .innerWrapper .contantSec .headFirst {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.applicantSec .innerWrapper .contantSec .headFirst h2 {
  color: #fb5100;
  font: 17px "basis_grotesque_proregular";
  margin: 0;
  margin-right: 16px;
}
.applicantSec .innerWrapper .contantSec .headFirst .tag {
  color: #3f2355;
  padding: 5px 10px;
  font: 12px "basis_grotesque_proregular";
  background-color: #f1ecf5;
  margin-right: 9px;
  border-radius: 10px;
}
.applicantSec .innerWrapper .contantSec .headFirst .date, .applicantSec .innerWrapper .contantSec .headSecond .date {
  color: #000000;
  display: flex;
  align-items: center;
  font: 12px "BasisGrotesquePro-Medium";
  margin-left: auto;
}
.applicantSec .innerWrapper .contantSec .headFirst .date .circle, .applicantSec .innerWrapper .contantSec .headSecond .date .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-right: 5px;
}
.applicantSec .innerWrapper .agentSec {
  border-radius: 50px;
  font: 14px "basis_grotesque_proregular";
  /* padding: 5px 10px; */
  background-color: #f2f2f2;
  cursor: pointer;
  margin-top: 10px;
  color: #3f2355;
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
  width: 145px;
}
.admin_flex{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.applicantSec .innerWrapper .headSecond {
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  margin-top: 9px;
}
.applicantSec .innerWrapper .headSecond .item {
  display: flex;
  align-items: center;
  font: 12px "BasisGrotesquePro-Medium";
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 5px;
}
.applicantSec .innerWrapper .headSecond .item img {
  margin-right: 3px;
  width: 15px;
}

/* delear sec */
.applicationHeaderAdmin .dealerSec {
  position: relative;
  box-shadow: 0px 4px 13px -6px rgba(19, 18, 18, 0.13);
  margin-bottom: 10px;
}
.applicationHeaderAdmin .dealerSec .typeTag {
  width: 64px;
}
.dealer__img{
top: 64px !important;
}
.applicationHeaderAdmin .dealerSec .innerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px 10px 10px;
  flex-wrap: wrap;
  width: 100%;
}
.applicationHeaderAdmin .dealerSec .innerWrapper .leftSec h2 {
  color: #fb5100;
  font: 14px "basis_grotesque_proregular";
  margin: 0;
  margin-top: 5px;
}
.applicationHeaderAdmin .dealerSec .innerWrapper p {
  color: #000000;
  margin: 0;
  font: 12px "BasisGrotesquePro-Medium"  !important;
}
.applicationHeaderAdmin .dealerSec .innerWrapper .rightSec {
  display: flex;
  align-items: flex-end;
}
.applicationHeaderAdmin .dealerSec .innerWrapper .rightSec .flexCenter {
  margin-top: 5px;
  margin-right: 20px;
  font: 12px "BasisGrotesquePro-Medium";
  margin-right: 5px;
  white-space: nowrap;
}
.applicationHeaderAdmin .typeTag{
  top: 109px;
  position: absolute;
  height: 48px;
}
.applicationHeaderAdmin .dealerSec .innerWrapper .flexCenter span {
  margin-left: 5px;
  font: 14px "BasisGrotesquePro-Medium";
}
.applicationHeaderAdmin .dealerSec .innerWrapper .flexCenter img {
  width: 15px;
}
.agentlinkstyle{
  width: 100%;
  display: flex;
  color: #3f2355;
    padding: 8px 15px;
    font: 14px "basis_grotesque_proregular";
    background-color: #F4EEF9;
    margin: 16px;
    border-radius: 50px;
    font-weight: 700;
    text-decoration: none;
  background-image: url(../image/Assign.svg);
  background-repeat: no-repeat;
  background-position: 15%;
}
.app-form-main .app-form-content{
  width: 70%;
}
.app-form-main .app-form-side-bar{
  width: 30% !important;
}
@media only screen and (max-width: 1500px){
  .app-form-main {
    padding: 53px 25px;
}
.app-form-main .back{
  left: 40px;
}
.app-form-main .app-form-content{
  width: 74% !important;
}
.app-form-main .app-form-side-bar{
  width: 26% !important;
}
}
@media screen and (max-width: 1299px) {

  .app-form-main .app-form-side-bar {
    width: 23% !important;
}
.app-form-main .app-form-content{
  width: 76% !important;
}
.app-form-main .app-form-content {
  padding-right: 10px !important;
}
.agentlinkstyle{
margin-bottom: -4px;
}
.app-form-main .app-form-side-bar .nav-list-item{
  flex-direction: column;
}
}
@media screen and (max-width: 1280px) {
  .contantSec{
    width: 100%;
  }
  .app-form-content{
padding-left: 19px !important;
  }
}
/* @media only screen and (max-width: 1200px){
  .app-form-main .app-form-content{
    width: 78%;
  }
  .app-form-main .app-form-side-bar{
    width: 22% !important;
  }
  .app-form-main .app-form-side-bar .nav-list-item{
    flex-direction: column;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .applicationHeaderAdmin .typeTag {
    transform-origin: 0 0;
    background: #fb5100;
    transform: rotate(270deg);
    max-width: 105px;
  }

  .applicationHeaderAdmin .applicantSec .innerWrapper {
    padding: 17px 16px 14px 70px;
  }
  .applicantSec .innerWrapper .contantSec .headFirst h2 {
    color: #FE5600;
    font: 18px "basis_grotesque_proregular";
    margin: 0;
    margin-right: 16px;
    font-weight: 500;
  }
  .applicantSec .innerWrapper .contantSec .headFirst .tag {
    color: #3f2355;
    padding: 5px 15px;
    font: 14px "basis_grotesque_proregular";
    background-color: #f1ecf5;
    margin-right: 9px;
    border-radius: 15px;
  }
  .applicantSec .innerWrapper .contantSec .headFirst .date, .applicantSec .innerWrapper .contantSec .headSecond .date {
    color: #000000;
    display: flex;
    align-items: center;
    font: 14px "BasisGrotesquePro-Medium";
  }
  .applicantSec .innerWrapper .contantSec .headFirst .date .circle, .applicantSec .innerWrapper .contantSec .headSecond .date .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background-color: #f2f2f2;
    margin-right: 7px;
  }
  .applicantSec .innerWrapper .agentSec {
    border-radius: 50px;
    font: 16px "basis_grotesque_proregular";
    padding: 10px 20px;
    background-color: #f2f2f2;
    cursor: pointer;
    margin-top: 0;
  }
  .applicantSec .innerWrapper .headSecond {
    display: flex;

    align-items: center;
    margin-top: 9px;
  }
  .applicantSec .innerWrapper .headSecond .item {
    display: flex;
    align-items: center;
    font: 14px "BasisGrotesquePro-Medium";
    margin-right: 23px;
    white-space: nowrap;
  }
  .applicantSec .innerWrapper .headSecond .item img {
    margin-right: 8px;
    width: auto;
  }

  /* dealer Sec */
  .applicationHeaderAdmin .dealerSec .innerWrapper {
    padding: 11px 16px 11px 70px;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper .leftSec h2 {
    color: #fb5100;
    font: 16px "basis_grotesque_proregular";
    margin: 0;
    margin-top: 5px;
    font-weight: 500;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper p {
    color: #000000;
    font: 12px "basis_grotesque_proregular";
    margin: 0px 0px 5px 0px !important;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper .rightSec {
    display: flex;
    align-items: flex-end;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper .rightSec .flexCenter {
    margin-top: 5px;
    margin-right: 20px;
    font: 14px "BasisGrotesquePro-Medium";
    margin-right: 10px;
    white-space: nowrap;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper .flexCenter span {
    margin-left: 5px;
    font-size: 14px;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper .flexCenter img {
    width: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .applicationHeaderAdmin .typeTag {
    max-width: 109px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1300px) {
  .applicationHeaderAdmin .typeTag {
    max-width: 109px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1380px) {
  .applicationHeaderAdmin .typeTag {
    max-width: 109px;
  }
  .applicationHeaderAdmin .dealerSec .innerWrapper {
    padding: 11px 16px 11px 70px;
  }
  .applicationHeaderAdmin .applicantSec .innerWrapper {
    padding: 17px 16px 14px 70px;
  }

}
.agentBtnRight{
  text-align: right;
  min-width: 170px;
}
.agentBtnRight a{
  text-decoration: none;
}
.agentBtnRight .agentlinkstyle{
  margin: 0px;
  width: auto;
  max-width: 100%;
  height: 41px;
  display: inline-block;
  padding: 12px 20px;
  background-color: #FFECE4;
  border-radius: 6px;
}
.applicationRighTag{
  display: flex;
  align-items: center;
}
.nav-tabs-2 .nav-item .tabs-text.personal-detail{
  color: #FE5600;
}
.app-form-main .tab-content{
  padding-top: 23px;
}
.app-form-main h1{
  font-size: 22px;
  font-weight: 700;
}
.app-form-main h1.lineNone:after, .app-form-main h1.lineNone:before{
  display: none;
}
#formAppTabsContent .tab-pane .forms-head h1:after, #formAppTabsContent .tab-pane .forms-head h1:before{
  display: none;
}
.app-form-main .form-main{
  margin-top: 10px;
  padding: 30px 33px;
}
.AddCobtn{
  color: #FE5600;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  width: 165px;
  justify-content: space-between;
  padding-right: 0px;
  align-items: center;
}
.AddCobtn i{
  font-size: 28px;
}
.app-form-main .form-main .form-control{
  border-radius: 8px;
}
.app-form-main .react-select-main .react-select__control {
  border-radius: 8px !important;
}
.app-form-main .form-main .form-field-row{
  margin-top: 20px;
}
@media only screen and (min-width: 1280px) {
  .rightDealerSec{
    min-width: 320px;
  }
}