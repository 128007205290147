/* CookieBanner.css */
.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #222;
    color: #fff;
    padding: 15px;
    z-index: 1000;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
}

.cookie-banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.cookie-banner-text {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
}

.cookie-banner-link {
    color: #f1c40f;
    text-decoration: underline;
}

.cookie-banner-button {
    margin-left: 15px;
    padding: 8px 15px;
    background-color: #fb5100;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.cookie-banner-button:hover {
    background-color: #d4ac0d;
}
