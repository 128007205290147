.banner-coreDealer.heroBannerWrapper .bannerDescriptionWrapper p {
	color: #3F3F3F;
}
@media screen and (min-width: 1441px) {
	.banner-coreDealer.heroBannerWrapper .bannerDescriptionWrapper p{
		padding: 0px 220px 0px 4px;
	}
	.heroBannerWrapper .bannerImg-Wrapper{
		width: 60% !important;
	}
}
@media screen and (max-width: 1390px){
	.banner-coreDealer.heroBannerWrapper .bannerImg-Wrapper img {
		right: 0px;
	}
}
@media screen and (max-width: 767px) {
	.banner-coreDealer.heroBannerWrapper .bannerDescriptionWrapper .btnWrapper .fs__btn{
		min-width: 200px;
	}
}
.solutionsBar{
	background: #FFECE4;
	text-align: center;
	padding: 26px 20px;

}
.solutionsBar .innerSolution{
	align-items: center;
    display: flex;
	justify-content: center;
	text-align: left;
}
.solutionsBar .number{
	width: 75px;
	height: 75px;
	background: #F9D9CC;
	color: #FB5100;
	letter-spacing: -0.03em;
	font-size: 55px;
	font-family: "BasisGrotesquePro-Black";
	text-align: center;
	border-radius: 100px;
	margin-right: 6px;
	line-height: 75px;
	flex-basis: 75px;
    flex-grow: 0;
    flex-shrink: 0;
}
.solutionsBar h3{
	font-size: 25px;
	letter-spacing: -0.03em;
	color: #FB5100;
	font-family: "BasisGrotesquePro-Medium";
	margin: 0px;

}
@media screen and (min-width: 1601px) {
	.solutionsBar .number{
		font-size: 87px;
		line-height: 102px;
		width: 102px;
		height: 102px;
		flex-basis: 102px;
		flex-grow: 0;
		flex-shrink: 0;
	}
	.solutionsBar h3{
		font-size: 35px;
	}
}
@media screen and (max-width: 767px) {
	.solutionsBar .innerSolution{
		max-width: 235px;
		margin: auto;
	}
	.solutionsBar .number{
		width: 48px;
		height: 48px;
		line-height: 48px;
		font-size: 40px;
		flex-basis: 48px;
		flex-grow: 0;
		flex-shrink: 0;
	}
	.solutionsBar h3{
		font-size: 17px;
	}
}

.signUp-core-section .qualifiedStepsMainWrap{
	/* margin-top: 52px; */
}
.signUp-core-section .qualifiedStepsMainWrap h2 {
    color: #3F2355;
    letter-spacing: -1px;
    margin-bottom: 43px;
    font-family: "basis_grotesque_probold";
    font-size: 28px;
}

.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap {
    list-style: none;
    margin: 0 0 65px;
    padding: 0;
    counter-reset: css-counter;
}

.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li {
    position: relative;
    margin-bottom: 37px;
}

.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li:last-child {
    margin-bottom: 0
}

.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li h3:before {
    counter-increment: css-counter;
    content: counter(css-counter);
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    background: linear-gradient(48.23deg, #E32738 19.15%, #E72D30 23.04%, #F03E1B 35.44%, #F6490C 49.25%, #FA5003 65.47%, #FB5200 89.97%);
    color: #fff;
    font-family: "basis_grotesque_probold";
    position: absolute;
    top: 0;
    left: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li h3 {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.3px;
    color: #3F2355;
    margin-bottom: 5px;
    font-family: "basis_grotesque_probold";
	position: relative;
	padding-left: 45px;
	min-height: 34px;
	padding-top: 4px;
}

.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li p {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    font-family: "basis_grotesque_proregular";
	max-width: 576px;
}
.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li p a{
	color: #FB5100;
	font-family: "basis_grotesque_probold";
}



.signUp-core-section .qualifiedStepsMainWrap .btnWrapper .btn {
    margin-bottom: 5px;
    font-family: "basis_grotesque_probold";
    text-decoration: none !important
}
@media screen and (min-width: 1601px) {
	.signUp-core-section .qualifiedStepsMainWrap h2 {
		margin-bottom: 43px;
		font-size: 32px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap {
		margin: 0 0 65px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li {
		margin-bottom: 37px;
	}

	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li h3:before {
		width: 32px;
		height: 32px;
		font-size: 18px;
		line-height: 32px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li h3 {
		font-size: 23px;
		line-height: 30px;
		margin-bottom: 5px;
		padding-left: 45px;
		min-height: 40px;
		padding-top: 4px;
	}

	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li p {
		font-size: 20px;
		line-height: 24px;
	}

	.signUp-core-section .qualifiedStepsMainWrap .btnWrapper .btn {
		margin-bottom: 5px;
	}
}
@media screen and (max-width: 767px) {
	.signUp-core-section .qualifiedStepsMainWrap {
		margin-top: 28px;
	}
	.signUp-core-section .qualifiedStepsMainWrap h2 {
		margin-bottom: 20px;
		font-size: 18px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap {
		margin: 0 0 35px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li {
		margin-bottom: 20px;
	}

	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li h3:before {
		width: 24px;
		height: 24px;
		font-size: 13px;
		line-height: 24px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li h3 {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 10px;
		padding-left: 36px;
		min-height: 25px;
		padding-top: 4px;
	}

	.signUp-core-section .qualifiedStepsMainWrap .qualifiedStepsWrap li p {
		font-size: 14px;
		line-height: 20px;
	}
	.signUp-core-section .qualifiedStepsMainWrap .btnWrapper {
		text-align: center;
	}

	.signUp-core-section .qualifiedStepsMainWrap .btnWrapper .btn {
		margin-bottom: 5px;
		padding-top: 8px;
		padding-bottom: 8px;
	}
}

.videoAreaRight{
	height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
	filter: drop-shadow(0px 0px 98px rgba(0, 0, 0, 0.08));
}
.videoAreaRight iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
	
}
@media screen and (max-width: 767px) {
	.videoAreaRight{
		margin-top: 40px;
	}
}
.comprehensiveSignUp{
	background: #FFFAF9;
}

.fs__btn {
    display: inline-block;
    font-family: "BasisGrotesquePro-Medium";
    font-size: 18px;
    color: #fff;
    background: #fb5100;
    border: 2px solid #fb5100;
    padding: 11px 40px 15px 25px;
    font-weight: 500;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    min-width: 227px;
    text-align: center;
    position: relative;
    letter-spacing: 0
}
.fs__btn .icon__arrow {
    width: 23px;
    height: 11px;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 23px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.fs__btn .icon__arrow img {
    width: 100%;
    height: auto;
    vertical-align: top
}

.fs__btn:hover {
    color: #fff;
    background: #3f2355;
    border: 2px solid #3f2355;
    text-decoration: none !important
}