.ft__heroBannerWrapper {
  padding: 121px 161px 82px;
}
@media screen and (max-width: 1600px) {
  .ft__heroBannerWrapper {
    padding: 121px 100px 82px;
  }
}
@media screen and (max-width: 1280px) {
  .ft__heroBannerWrapper {
    padding: 120px 60px 60px;
  }
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper {
    padding: 40px 30px;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper {
  padding: 21px 0;
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper {
    padding-top: 10px;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper h1 {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #53356F;
  margin-bottom: 27px;
}
@media screen and (max-width: 1280px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper h1 {
    font-size: 45px;
    line-height: 45px;
    letter-spacing: -1px;
  }
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper h1 {
    font-size: 33px;
    line-height: 33px;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper h4 {
  font-size: 29px;
  line-height: 42px;
  color: #5A687C;
  font-family: "BasisGrotesquePro-Medium";
  margin-bottom: 23px;
  font-weight: 500;
  letter-spacing: 0;
  padding-left: 2px;
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper h4 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 25px;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper p {
  font-size: 20px;
  line-height: 25px;
  color: #8d97a7;
  padding: 0 85px 0 4px;
  letter-spacing: 0px;
  margin-bottom: 31px;
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper p {
    font-size: 15px;
    line-height: 22px;
    padding: 0;
    margin-bottom: 40px;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper .ft__btnWrapper {
  margin-bottom: 22px;
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper .ft__btnWrapper .ft__btn {
    width: 100%;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper .ft__bannerLogoWrap {
  max-width: 251px;
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper .ft__bannerLogoWrap {
    margin: 0 auto;
  }
}
.ft__heroBannerWrapper .ft__bannerDescriptionWrapper .ft__bannerLogoWrap img {
  width: 100%;
  height: auto;
}
.ft__heroBannerWrapper .ft__infoBannerInfographWrap {
  text-align: right;
}
.ft__heroBannerWrapper .ft__infoBannerInfographWrap img {
  width: 625px;
  height: auto;
  position: relative;
  top: -14px;
}
@media screen and (max-width: 1600px) {
  .ft__heroBannerWrapper .ft__infoBannerInfographWrap img {
    width: 550px;
  }
}
@media screen and (max-width: 1280px) {
  .ft__heroBannerWrapper .ft__infoBannerInfographWrap img {
    width: 440px;
  }
}
@media screen and (max-width: 991px) {
  .ft__heroBannerWrapper .ft__infoBannerInfographWrap img {
    width: 100%;
  }
}

.ft__introSectionWrapper {
  padding: 103px 121px 59px;
  background: #FFF7F3;
}
@media screen and (max-width: 1600px) {
  .ft__introSectionWrapper {
    padding: 150px 50px 140px;
  }
}
@media screen and (max-width: 1440px) {
  .ft__introSectionWrapper {
    padding: 100px 50px;
  }
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper {
    padding: 70px 30px;
  }
}
.ft__introSectionWrapper .section__header {
  margin-bottom: 77px;
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .section__header {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 767px) {
  .ft__introSectionWrapper .section__header {
    margin-bottom: 30px;
  }
}
.ft__introSectionWrapper .section__header h2 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 900;
  letter-spacing: -0.03em;
  color: #53356F;
  text-align: center;
  margin: 0;
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .section__header h2 {
    font-size: 33px;
    line-height: 42px;
  }
}
@media screen and (max-width: 767px) {
  .ft__introSectionWrapper .section__header h2 {
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introMockupGrid {
    max-width: 47%;
    flex: 0 0 47%;
    padding: 0;
  }
}
.ft__introSectionWrapper .ft__introMockupWrap {
  padding: 10px 15px 10px 15px;
  text-align: right;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introMockupWrap {
    padding-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .ft__introSectionWrapper .ft__introMockupWrap {
    padding: 0 0 30px;
  }
}
.ft__introSectionWrapper .ft__introMockupWrap img {
  width: 675px;
  height: auto;
  max-width: 100%;
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introMockupWrap img {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introDescGrid {
    max-width: 53%;
    flex: 0 0 53%;
  }
}
.ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap {
  padding: 37px 145px 0 22px;
  margin-bottom: 50px;
}
@media screen and (max-width: 1600px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap {
    padding: 0 80px 0 22px;
  }
}
@media screen and (max-width: 1140px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap {
    padding: 0 40px 0 22px;
  }
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap {
    padding: 0 30px 0 22px;
  }
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap {
    padding: 0;
    margin: 0;
  }
}
.ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul {
  padding: 0;
  margin: 0 0 82px;
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul {
    margin: 0 0 50px;
  }
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul {
    margin: 0 0 30px;
  }
}
.ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li {
  list-style: none;
  margin-bottom: 36px;
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li {
    margin-bottom: 20px;
  }
}
.ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li h3 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 900;
  letter-spacing: -0.8px;
  color: #53356F;
  margin-bottom: 0;
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li h3 {
    font-size: 22px;
    line-height: 30px;
  }
}
.ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li p {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0;
  margin: 0;
}
@media screen and (max-width: 1280px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 991px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap ul li p {
    font-size: 15px;
    line-height: 23px;
  }
}
.ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap .ft__btnWrapper {
  position: relative;
  left: -4px;
}
@media screen and (max-width: 767px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap .ft__btnWrapper {
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .ft__introSectionWrapper .ft__introDescGrid .ft__introDescWrap .ft__btnWrapper .ft__btn {
    width: 100%;
  }
}

.ft__loanFeaturesWrapper {
  padding: 80px 0 190px;
  overflow: hidden;
}
@media screen and (max-width: 1600px) {
  .ft__loanFeaturesWrapper {
    padding: 112px 0 150px;
  }
}
@media screen and (max-width: 1440px) {
  .ft__loanFeaturesWrapper {
    padding: 100px 0;
  }
}
@media screen and (max-width: 1280px) {
  .ft__loanFeaturesWrapper {
    padding: 50px 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper {
    padding: 60px 15px;
  }
}
@media screen and (max-width: 767px) {
  .ft__loanFeaturesWrapper {
    padding: 60px 15px;
  }
}
@media screen and (min-width: 1100px) {
  .ft__loanFeaturesWrapper > .container {
    max-width: 1115px;
  }
}
.ft__loanFeaturesWrapper .ft__sectionHeader {
  text-align: center;
  margin-bottom: 102px;
}
@media screen and (max-width: 1440px) {
  .ft__loanFeaturesWrapper .ft__sectionHeader {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1280px) {
  .ft__loanFeaturesWrapper .ft__sectionHeader {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper .ft__sectionHeader {
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 767px) {
  .ft__loanFeaturesWrapper .ft__sectionHeader {
    margin-bottom: 100px;
  }
}
.ft__loanFeaturesWrapper .ft__sectionHeader h2 {
  color: #53356F;
  letter-spacing: -1px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper .ft__sectionHeader h2 {
    font-size: 33px;
    line-height: 36px;
    padding: 0 8%;
  }
}
@media screen and (max-width: 767px) {
  .ft__loanFeaturesWrapper .ft__sectionHeader h2 {
    font-size: 33px;
    line-height: 36px;
  }
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper {
  position: relative;
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .dots__imageTopWrap {
  position: absolute;
  top: -68px;
  right: -78px;
  z-index: 1;
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .dots__imageBottomWrap {
  position: absolute;
  bottom: -47px;
  left: -60px;
  z-index: 1;
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner {
  position: relative;
  z-index: 2;
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap {
  padding: 0 20px;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap {
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid {
  font-size: 14px;
  padding: 54px 30px 46px;
  -webkit-box-shadow: 0px 0px 18px rgba(108, 126, 147, 0.15);
  -moz-box-shadow: 0px 0px 18px rgba(108, 126, 147, 0.15);
  -o-box-shadow: 0px 0px 18px rgba(108, 126, 147, 0.15);
  box-shadow: 0px 0px 18pxrgba 108, 126, 147, 0.15;
  background: #fff;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid {
    padding: 40px 30px 36px;
  }
}
@media screen and (max-width: 767px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid {
    padding: 44px 44px 30px 44px;
  }
}
@media screen and (max-width: 427px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid {
    padding: 35px;
  }
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid .ft__featureGridIcon {
  width: 75px;
  height: auto;
  margin: 0 auto 32px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid .ft__featureGridIcon {
    margin: 0 auto 25px;
  }
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid .ft__featureGridIcon img {
  width: 100%;
  height: auto;
}
.ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid h3 {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.5px;
  color: #3F2355;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid h3 {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #3F2355;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 767px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid h3 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 427px) {
  .ft__loanFeaturesWrapper .ft__loanFeaturesListWrapper .ft__loanFeaturesListInner .ft__featureGridWrap .ft__featureGrid h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .ft__heroBannerWrapper .ft__bannerDescriptionWrapper h4{
    font-size: 18px !important;
  }
  .vehicle-budget-main .select-vehicle-box p{
    font-size: 11px;
  }
}

.ft__whyChooseUsWrapper {
  background: #FFECE4;
}
.ft__whyChooseUsWrapper .ft__whyChooseUsRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow {
    position: relative;
    overflow: hidden;
  }
}
.ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid {
  width: 100%;
}
.ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridLarge {
  max-width: calc(100% - 690px);
  padding: 125px 90px 152px 197px;
}
@media screen and (max-width: 1600px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridLarge {
    max-width: calc(100% - 540px);
    padding: 100px 50px 98px 100px;
  }
}
@media screen and (max-width: 1154px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridLarge {
    padding: 70px 40px 80px 50px;
  }
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridLarge {
    max-width: 100%;
    position: relative;
    z-index: 1;
    background: rgba(255, 236, 228, 0.9);
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridLarge {
    padding: 50px 30px 60px;
  }
}
.ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridSmall {
  max-width: 690px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
@media screen and (max-width: 1600px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridSmall {
    max-width: 540px;
  }
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__whyChooseUsRow .ft__whyChooseUsMainGrid.ft__whyChooseUsMainGridSmall {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    background-position: top center;
    top: 2px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader {
  margin-bottom: 68px;
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader {
    margin-bottom: 30px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 {
  color: #53356f;
  letter-spacing: -1px;
  margin-bottom: 25px;
}
@media screen and (max-width: 1091px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 {
    font-size: 34px;
    line-height: 44px;
  }
}
@media screen and (max-width: 1091px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 {
    font-size: 32px;
    line-height: 38px;
  }
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 {
    margin-bottom: 18px;
  }
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 br {
    content: "";
  }
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 br:after {
    content: " ";
  }
}
@media screen and (max-width: 991px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 {
    font-size: 36px;
    line-height: 46px;
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader h2 {
    font-size: 30px;
    line-height: 36px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  letter-spacing: 0;
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader p {
    font-size: 16px;
    line-height: 24px;
  }
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader p br {
    content: "";
  }
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader p br:after {
    content: " ";
  }
}
@media screen and (max-width: 991px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader p {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__loanIntakeHeader p {
    font-size: 16px;
    line-height: 24px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 69px;
}
@media screen and (max-width: 1154px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper {
    margin-bottom: 30px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li {
  width: 100%;
  max-width: 385px;
  padding: 0 20px;
}
@media screen and (max-width: 1706px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li {
    max-width: 50%;
  }
}
@media screen and (max-width: 1154px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li {
    padding: 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap {
  padding: 46px;
  background: #fff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  text-align: center;
}
@media screen and (max-width: 1297px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap {
    padding: 40px 20px;
  }
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap {
    padding: 30px 15px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .count {
  font-size: 84px;
  line-height: 84px;
  font-weight: 700;
  letter-spacing: -0.03em;
  color: #FB5100;
  margin-bottom: 6px;
}
@media screen and (max-width: 1297px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .count {
    font-size: 80px;
    line-height: 80px;
  }
}
@media screen and (max-width: 1084px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .count {
    font-size: 75px;
    line-height: 75px;
  }
}
@media screen and (max-width: 1059px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .count {
    font-size: 70px;
    line-height: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .count {
    font-size: 55px;
    line-height: 55px;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .desc {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: -0.03em;
  color: #000000;
}
@media screen and (max-width: 1297px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .desc {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (max-width: 1084px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .financial__gridsWrapper li .financial__factsWrap .desc {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  .ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap .ft__btnWrapper .ft__btn {
    width: 100%;
  }
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap.ft__loanIntakeStepsMainWrapAlt {
  padding: 0 0 0 35px;
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap.ft__loanIntakeStepsMainWrapAlt .ft__loanIntakeStepsWrap {
  margin-bottom: 0;
}
.ft__whyChooseUsWrapper .ft__loanIntakeStepsMainWrap.ft__loanIntakeStepsMainWrapAlt .ft__loanIntakeStepsWrap li p {
  padding-right: 33px;
}
.ft__whyChooseUsWrapper .ft__whyChooseUsImageWrap img {
  width: 100%;
  height: auto;
}

.container {
  max-width: 1170px;
}
