.status{
    background: #D1E2FB;
    border-radius: 65px;
    padding: 4px 10px;
}

.heading{
    padding: 15px;
}
.Approved {
  margin: 0;
  padding: 0;
  background: #D4F7A7;
  border-radius: 65px;
  border: none;
  outline: none;
  font-family: "basis_grotesque_probold";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
  color: #6CBE03;
  width: 142px;
  height: 28px;
  line-height: 28px;
}
.unverified__status{
  color: #0B4BAC;
  background: #65BDD9;
}
.manual{
color: #04BCB1;
background: #D1FFE6;
}
.special_pro{
  background: #DADADA;
  color: #676767;
}

.deleteSelected{
    float: right !important;
}
.inline{
  display: -webkit-inline-box;
  line-height: 30px;
  font-size: 14px;
  margin: 10px 2px 4px 7px;
}
.inline label{
    padding: 4px;
    }
    page-length{
      font-weight: 500;
      /* background: #EBEBEF;
    border: #EBEBEF;
    border-radius: 5px;
    padding: 2px; */
    }
    .recordcounter{
      background: #EBEBEF;
      border: 1px solid #EBEBEF;
      border-radius: 4px;
      padding: 1px;
      padding: 4px;
    }
    
.searchBtn{
    margin-left: 6px;
width: 75px;
height: 40px;
border: 0ch;
background: #FB5100;
border-radius: 4px;
}
.text-align-center{
  text-align: center;
}

.tableDealer {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.tableDealerHeight{
  background-color: #D4D3E0;
  border: 2px solid #C1C0CD; 
  white-space: nowrap;
}
.tableDealerHeight th {
  padding: 4px 7px;
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    border-bottom-color: #E4E7EB !important;
    font-family: "BasisGrotesquePro-Medium";
}
.tr td{
  margin-right: 12px;
}
.icon-delete button, .icon-delete a{
    border: none;
    outline: none;
    background: none;
}
.dealer-dtable tr td {
  font-size: 13px;
    color: #667085;
    font-weight: normal;
    font-family: "basis_grotesque_proregular";
    padding: 13px 7px;
    line-height: 19px;
    white-space: nowrap;
}
.DateRangePicker-modal{
  width: 45vw;
  align-items: center;
  max-width: 90vw;
}
.btnDone{
  background-color: #ECE8F4;
  border:0ch;
  width: 59px;
  height: 33px;
  background: #ECE8F4;
border-radius: 2px;
}
.justify-content{
  justify-content: normal;
}
.space-between {
  justify-content: space-between;
  width: 100%;
  padding-right: 1vw;
  display: flex;
}
.TDays{
  width: 70px;
  height: 33px;
  background: #F6F7F7;
  border-radius: 4px;
  padding: 3px;
  text-align: center;
}
.tText{
  color: #475E68;
}
.activePage{
  display: inline-block;
  margin: 0 0 0 4px;
  padding: 0 0;
  background: #FB7333;
  /* background: #fbe8e0; */
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: "BasisGrotesquePro-Medium";
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  width: 28px;
  height: 28px;
  color: #FFFFFF;
  /* color: #828385; */
  line-height: 28px;
  cursor: pointer;
  box-shadow: 0px 0px 3px 1px rgba(254, 86, 0, 0.23);
}
.firstPage{
  display: inline-block;
  margin: 0 0 0 4px;
  padding: 0 0;
  background: #EAEAEB;
  /* background: #fbe8e0; */
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: "BasisGrotesquePro-Medium";
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  width: 28px;
  height: 28px;
  color: #5E6F81;
  /* color: #828385; */
  line-height: 28px;
  cursor: pointer;
}
.btnClose{
  background-color: #FFFFFF;
  border:0ch;
  width: 59px;
  height: 33px;
  color: #8D9BA1;
}
.newAppBtn{
  background: #644E77;
    border-radius: 4px;
    border: none;
    outline: none;
    line-height: 16px;
    color: #ffffff;
    float: right;
    padding: 0px 16px;
    margin: 0px 10px;
    font-size: 12px;
    height: 32px;
}
.deleteSelected{
  background: #fb5100;
  border-radius: 4px;
  border: none;
  outline: none;
  line-height: 16px;
  color: #ffffff;
  padding: 0px 15px;
  font-size: 12px;
  height: 32px;
}
/* .Title{
    position: absolute;
    width: 429px;
    height: 32px;    
    font-family: Basis Grotesque Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 124%;    
    display: flex;
    align-items: center;    
    color: #3F2355;
} */

ul.customepagination{
    float: left !important;
    margin: 0px 0 30px 0 !important;
  }
  .customepagination .page-link {
    border-radius: 15px;
  }

  .customepagination .page-item:first-child .page-link {
    width: 15px;
  }

  .detaillinkbtn{
    color: #fb5100;
    background: #F9D9CC;
    border-radius: 65px;
    padding: 6px 17px;
    text-decoration: none;
    font-size: 12px;
  }
  .deletelinkbtn{
    color: #FF0000;
  }

  
.DateRangePicker__CalendarSelection--start{
  left: 5px;
    border-radius: 50%;
    color: #fefefe !important;
    background: #644E77 !important;
    right: 5px;
    border: none;
}
.DateRangePicker__CalendarHighlight--single{
  background-color: #644E77;
    border: 1px solid #644E77;
    border-radius: 50%;
    left: 5px;
    right: 5px;
    color: #fff !important;
}
.DateRangePicker__CalendarSelection {
  background-color: #F0F4FA;
  border: 1px solid #F0F4FA;
  bottom: 5px;
  left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
}
.DateRangePicker__Date--is-selected {
    color: #475E68;
}
.DateRangePicker__CalendarSelection--start ~ .DateRangePicker__DateLabel {
  color: #fff !important;
}
.DateRangePicker__Date--is-highlighted span{
       color: #fff !important;
}